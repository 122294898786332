import React, { useState } from "react";

import SideList from "./sideBar/SideList";
import DarkMode from "./sideBar/DarkMode";

import logo from "../../assets/DataStems_Logo_Stacked.png";

import { useSelector } from "react-redux";

import UserDisplay from "./sideBar/UserDispaly";

const SideBar = ({ setDashMain }) => {
  const database = useSelector((state) => state.database);
  const userState = useSelector((state) => state.userState);
  const [openList, setOpenList] = useState({ current: 0 });
  return (
    <div className="side-bar relative">
      <div className="side-bar-logo-wrapper">
        <img src={logo} alt="logo" width="32px" height="32px" />
      </div>
      <UserDisplay userState={userState} />

      <div
        onClick={(_) => {
          setOpenList(0);
          setDashMain(true);
        }}
        className="side-label side-label-dash"
      >
        Dashboard
      </div>

      <div className="side-list-inner-wrapper">
        <SideList
          setDashMain={setDashMain}
          label="Retail"
          list={database.retail}
          current={database.currentCompany}
          listN={1}
          openList={openList}
          setOpenList={setOpenList}
        />
        <SideList
          setDashMain={setDashMain}
          label="Restaurant"
          list={database.resto}
          current={database.currentCompany}
          listN={2}
          openList={openList}
          setOpenList={setOpenList}
        />
      </div>
      <DarkMode />
    </div>
  );
};

export default SideBar;
