import { Card } from "@lightspeed/flame/Card";
import { Input } from "@lightspeed/flame/Input";
import { Button } from "@lightspeed/flame/Button";

//import { useForm } from "react-hook-form";

import styled from "@emotion/styled";
import { IconSync } from "@lightspeed/flame/Icon/Sync";
import { useSelector, useDispatch } from "react-redux";
//import { uiSelector } from "../../../redux/slices/uiSlice";

const StyledCard = styled(Card)`
  margin-left: 16px;
`;

const StyledGroup = styled.div`
  margin: 16px 0;
  label {
    margin-left: 4px;
    margin-bottom: 4px;
    display: block;
  }
`;

const InputGroup = ({ register, name, label }) => {
  const today = new Date().toISOString().substr(0, 10);

  return (
    <StyledGroup>
      <label htmlFor={name}>{label}</label>
      <Input
        id={name}
        name={name}
        type="date"
        defaultValue={today}
      />
    </StyledGroup>
  );
};

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const DatePicker = ({ updateAction }) => {
  //const {handleSubmit } = useForm();
  //const { theme } = useSelector(uiSelector);
  const dispatch = useDispatch();

  const onSubmit = (e) => {
    dispatch(
      updateAction({
        ...e,
      })
    );
  };

  return (
    <StyledCard>
      <form>
        <InputGroup name="start_date" label="Start Date:" />
        <InputGroup name="end_date" label="End Date:" />
        <ButtonWrapper>
          <Button type="submit">
            Sync
            <IconSync/>
          </Button>
        </ButtonWrapper>
      </form>
    </StyledCard>
  );
};

export default DatePicker;
