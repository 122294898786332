import React, { useRef, useState, useEffect } from "react";

import PaymentTable from "../tables/PaymentTable";
import TaxesTable from "../tables/TaxesTable";
import CategoriesTable from "../tables/CategoriesTable";
import CategoriesTableExtended from "../tables/CategoriesTableExtended";
import CardsTableMulti from "../tables/CardsTableMulti";
import CategoriesTableRounded from "../tables/CategoriesTableRounded";
import CardsTables from "../tables/CardsTable";
import WithoutCategoriesTable from "../tables/WithoutCategoriesTable";
import PivotTableUI from "react-pivottable/PivotTableUI";
import "react-pivottable/pivottable.css";
import { useSelector } from "react-redux";
import Reports from "../tables/Reports";


const TableContent = () => {
  const [sortState, setSortState] = useState([0, 0, 0, 0, 0, 0]);
  const [notes, setNotes] = useState([]);
  const [settings, setSettings] = useState({});
  const ui = useSelector((state) => state.ui);
  const database = useSelector((state) => state.database);
  const scrollRef = useRef({ current: 0 });
  const [scrollState, setScrollState] = useState(true);

  const notes3 = [{"transaction_date":"2023-04-19","shopid":"1","shopname":"Club de Golf Saint-Raphael","gl_number":"G1","gl_description":"GLTAX1","debit":"0.00","credit":"10.17","total":"-10.17","mail_subject":""},{"transaction_date":"2023-04-19","shopid":"1","shopname":"Club de Golf Saint-Raphael","gl_number":"G2","gl_description":"GLTAX2","debit":"0.00","credit":"20.30","total":"-20.30","mail_subject":""},{"transaction_date":"2023-04-19","shopid":"1","shopname":"Club de Golf Saint-Raphael","gl_number":"1110","gl_description":"House Accounts","debit":"233.95","credit":"0.00","total":"233.95","mail_subject":""},{"transaction_date":"2023-04-19","shopid":"1","shopname":"Club de Golf Saint-Raphael","gl_number":"GL258","gl_description":"MSC","debit":"0.00","credit":"203.48","total":"-203.48","mail_subject":""}];
    /*
    const fetchData = () => {
    //setIsLoading(true);

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
          "token_user_id":1,
          "token_company_id":276410,
          "token_shop_id":1,
          "date_from":"2022-05-25",
          "date_to":"2022-05-27"
      }),
    };

    fetch("https://apimap.datastems.com/api/list/retail/reportXXX", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        //setIsLoading(false);
        setNotes(data);
      })
      .catch((error) => {
        //setIsLoading(false);
        //setIsError(true);
        console.log(error);
      });
  };
  useEffect(() => {
    fetchData();
  }, []);
  
*/
  const handleScroll = () => {
    if (scrollRef.current.scrollTop > 0) {
      if (scrollState !== false) {
        setScrollState(false);
      }
    } else {
      if (scrollState !== true) {
        setScrollState(true);
      }
    }
  };

  const changeSortState = (x) => {
    const newSortState = [0, 0, 0, 0, 0, 0, 0, 0, 0];
    switch (sortState[x]) {
      case 0:
        newSortState[x] = 1;
        break;
      case 1:
        newSortState[x] = 2;
        break;
      default:
        newSortState[x] = 0;
    }
    setSortState([...newSortState]);
  };

  const tables = [
    <PaymentTable
      scrollPos={scrollState}
      sortState={sortState}
      changeSortState={changeSortState}
      data={
        database.currentShop.payments &&
        database.currentShop.payments?.filter(
          (item) =>
            item?.account?.toLowerCase().indexOf(ui.search) > -1 ||
            item?.account_description?.toLowerCase().indexOf(ui.search) > -1 ||
            String(item?.paymenttypeid)?.indexOf(ui.search) > -1 ||
            item?.paymenttypeid_name?.toLowerCase().indexOf(ui.search) > -1
        )
      }
    />,
    ui.extendTable && database.currentCompany.type === "RETAIL" ? (
      <CategoriesTableExtended
        scrollPos={scrollState}
        sortState={sortState}
        changeSortState={changeSortState}
        data={
          database.currentShop.categories &&
          database.currentShop.categories?.filter(
            (item) =>
              item?.account?.toLowerCase().indexOf(ui.search) > -1 ||
              item?.account_description?.toLowerCase().indexOf(ui.search) >
                -1 ||
              (database.currentCompany.type === "RETAIL"
                ? String(item?.categoryid)?.indexOf(ui.search) > -1
                : String(item?.category_id)?.indexOf(ui.search) > -1) ||
              item?.category_name?.toLowerCase().indexOf(ui.search) > -1 ||
              String(item?.parentid)?.indexOf(ui.search) > -1 ||
              String(item?.cogs)?.indexOf(ui.search) > -1 ||
              String(item?.cogs_description)?.indexOf(ui.search) > -1
          )
        }
      />
    ) : (
      <CategoriesTable
        scrollPos={scrollState}
        sortState={sortState}
        changeSortState={changeSortState}
        data={
          database.currentShop.categories &&
          database.currentShop.categories?.filter(
            (item) =>
              item?.account?.toLowerCase().indexOf(ui.search) > -1 ||
              item?.account_description?.toLowerCase().indexOf(ui.search) >
                -1 ||
              (database.currentCompany.type === "RETAIL"
                ? String(item?.categoryid)?.indexOf(ui.search) > -1
                : String(item?.category_id)?.indexOf(ui.search) > -1) ||
              item?.category_name?.toLowerCase().indexOf(ui.search) > -1 ||
              String(item?.parentid)?.indexOf(ui.search) > -1
          )
        }
      />
    ),
    <CardsTableMulti data={database.currentShop.categoriesMulti} />,
    <CategoriesTableRounded
      scrollPos={scrollState}
      sortState={sortState}
      changeSortState={changeSortState}
      data={
        database.currentShop.categoriesRound &&
        database.currentShop.categoriesRound?.filter(
          (item) =>
            item?.account?.toLowerCase().indexOf(ui.search) > -1 ||
            item?.account_description?.toLowerCase().indexOf(ui.search) > -1 ||
            String(item?.categoryid)?.indexOf(ui.search) > -1 ||
            item?.category_name?.toLowerCase().indexOf(ui.search) > -1 ||
            String(item?.parentid)?.indexOf(ui.search) > -1
        )
      }
    />,
    <CardsTables data={database.currentShop.other} />,
    <WithoutCategoriesTable
      scrollPos={scrollState}
      sortState={sortState}
      changeSortState={changeSortState}
      data={
        database.currentShop.less &&
        database.currentShop?.less?.filter(
          (item) =>
            item?.description?.toLowerCase().indexOf(ui.search) > -1 ||
            item?.account_description?.toLowerCase().indexOf(ui.search) > -1 ||
            String(item?.categoryid)?.indexOf(ui.search) > -1 ||
            item?.category_name?.toLowerCase().indexOf(ui.search) > -1 ||
            String(item?.itemid)?.indexOf(ui.search) > -1
        )
      }
    />,

    
      //Reports
      //PivotTableUI
      <Reports
      
          scrollPos={scrollState}
          sortState={sortState}
          changeSortState={changeSortState}
          
          data={
            database.currentShop.reports  
            
          }
  
          //data={notes3}
          
          onChange={(s) => setSettings(s)}
          //cols={['']}
          rows={['transaction_date','gl_number','gl_description','debit','credit','total']}
          aggregatorName = 'Sum'
          vals={["total"]}
          // renderers={Object.assign({}, TableRenderers, PlotlyRenderers)}
          {...settings}
          hiddenAttributes={[
            "pvtRenderers",
            "pvtAxisContainer",
            "pvtVals",
            "pvtAxisContainer",
          ]}
          //hiddenFromAggregators={[]}
          
      />,

      <TaxesTable 
        scrollPos={scrollState}
        sortState={sortState}
        changeSortState={changeSortState}
        data={
          database.currentShop.taxes &&
          database.currentShop.taxes?.filter(
            (item) =>
              item?.account?.toLowerCase().indexOf(ui.search) > -1 ||
              item?.account_description?.toLowerCase().indexOf(ui.search) > -1 ||
              String(item?.tax_id)?.indexOf(ui.search) > -1 ||
              item?.tax_name?.toLowerCase().indexOf(ui.search) > -1
          )
        }
      />,
      
  ];
  return (
    <div className="table-content" onScroll={handleScroll} ref={scrollRef}>
      {tables[ui.table]}
    </div>
  );
};

export default TableContent;
