const initState = {
  theme: "flame",
  table: 0,
  notify: { error: true, errorMsg: "", show: false },
  confirm: { show: false, name: "", itemId: "" },
  busy: true,
  search: "",
  extendTable: false,
};

const uiReducer = (state = initState, action) => {
  switch (action.type) {
    case "CHANGE_UI_THEME":
      if (state.theme === "flame") {
        localStorage.setItem("theme", JSON.stringify("experimentaldark"));
        return { ...state, theme: "experimentaldark" };
      } else {
        localStorage.setItem("theme", JSON.stringify("flame"));
        return { ...state, theme: "flame" };
      }
    case "SET_UI_THEME":
      return { ...state, theme: action.payload };
    case "SET_CURRENT_TABLE":
      return { ...state, table: action.payload };
    case "SET_NOTIFY":
      return {
        ...state,
        notify: {
          error: action.payload.error,
          errorMsg: action.payload.msg,
          show: true,
        },
      };
    case "SET_NOTIFY_SHOW":
      return {
        ...state,
        notify: {
          ...state.notify,
          show: false,
        },
      };
    case "SET_CONFIRM":
      return {
        ...state,
        confirm: {
          show: action.payload.show,
          itemId: action.payload.id,
          name: action.payload.name,
        },
      };
    case "SET_BUSY":
      return {
        ...state,
        busy: action.payload,
      };
    case "SET_SEARCH":
      return {
        ...state,
        search: action.payload,
      };
    case "TOGGLE_EXTEND":
      return {
        ...state,
        extendTable: !state.extendTable,
      };
    default:
      return state;
  }
};

export default uiReducer;
