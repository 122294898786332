import React from "react";
import { Card } from "@lightspeed/flame/Card";

import { ReactComponent as Location } from "../../../assets/location.svg";

import * as databaseActions from "../../../redux/actions/databaseActions";
import { useDispatch } from "react-redux";

const LocationButton = ({ location, company, shop }) => {
  const dispatch = useDispatch();
  let active = false;
  let handleClick;
  if (company.type === "RETAIL") {
    active = location.shop_id === shop.shop_id;
    handleClick = (_) => {
      dispatch(databaseActions.setCurrentShopId(location.shop_id));
      dispatch(
        databaseActions.getCurrentRetailShop(company.id, location.shop_id)
      );
    };
  } else if (company.type === "RESTO") {
    active = location.area_id === shop.area_id;
    handleClick = (_) => {
      dispatch(databaseActions.setCurrentAreaId(location.area_id));
      dispatch(
        databaseActions.getCurrentRestoArea(company.id, location.area_id)
      );
    };
  }

  const dynamicClass = active ? "inverted" : "";
  return (
    <Card
      onClick={handleClick}
      top="true"
      className={`location-button ${dynamicClass}`}
    >
      <Location />
      {location.shop_name ? location.shop_name : location.area_name}
    </Card>
  );
};

export default LocationButton;
