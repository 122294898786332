import React from "react";

import { useSelector, useDispatch } from "react-redux";

import { Tab, TabContainer } from "@lightspeed/flame/Tab";
import { Card } from "@lightspeed/flame/Card";
import { Input } from "@lightspeed/flame/Input";
import { Button } from "@lightspeed/flame/Button";

import * as databaseActions from "../../redux/actions/databaseActions";
import { toggleExtend } from "../../redux/actions/uiActions";

import { setCurrentTable, setSearch } from "../../redux/actions/uiActions";

const CategorieBar = () => {
  const dispatch = useDispatch();
  const ui = useSelector((state) => state.ui);
  const database = useSelector((state) => state.database);
  const userState = useSelector((state) => state.userState);

  const shopTabContainer = (
    <TabContainer>
      <Tab
        active={ui.table === 0}
        onClick={(_) => {
          if (ui.table !== 0) {
            dispatch(setCurrentTable(0));
          }
          console.log("Payment Retail")
          dispatch(databaseActions.setShopPayments([]));
          dispatch(
            databaseActions.getShopPayments(
              database.currentCompany.id,
              userState.user.user_id,
              database.currentShop.shop_id
            )
          );
        }}
      >
        Payments
      </Tab>
      <Tab
        active={ui.table === 1}
        onClick={(_) => {
          if (ui.table !== 1) {
            dispatch(setCurrentTable(1));
          }
          dispatch(databaseActions.setShopCategories([]));
          dispatch(
            databaseActions.getShopCategories(
              database.currentCompany.id,
              userState.user.user_id,
              database.currentShop.shop_id
            )
          );
        }}
      >
        Categories
      </Tab>
      <Tab
        active={ui.table === 2}
        onClick={(_) => {
          if (ui.table !== 2) {
            dispatch(setCurrentTable(2));
          }
          dispatch(databaseActions.setShopCategoriesMulti([]));
          dispatch(
            databaseActions.getShopCategoriesMulti(
              database.currentCompany.id,
              userState.user.user_id,
              database.currentShop.shop_id
            )
          );
        }}
      >
        Categories (Multi GL)
      </Tab>
      <Tab
        active={ui.table === 3}
        onClick={(_) => {
          if (ui.table !== 3) {
            dispatch(setCurrentTable(3));
          }
          dispatch(databaseActions.setShopCategoriesRound([]));
          dispatch(
            databaseActions.getShopCategoriesRound(
              database.currentCompany.id,
              userState.user.user_id,
              database.currentShop.shop_id
            )
          );
        }}
      >
        Categories (round)
      </Tab>
      <Tab
        active={ui.table === 4}
        onClick={(_) => {
          if (ui.table !== 4) {
            dispatch(setCurrentTable(4));
          }
          dispatch(databaseActions.setShopCategoriesOther([]));
          dispatch(
            databaseActions.getShopCategoriesOther(
              database.currentCompany.id,
              database.currentShop.shop_id
            )
          );
        }}
      >
        Other (Over/Short,Tips) retail
        
      </Tab> 
    
      <Tab
        active={ui.table === 5}
        onClick={(_) => {
          if (ui.table !== 5) {
            dispatch(setCurrentTable(5));
          }
          dispatch(databaseActions.setShopCategoriesLess([]));
          dispatch(
            databaseActions.getShopCategoriesLess(
              database.currentCompany.id,
              userState.user.user_id,
              database.currentShop.shop_id
            )
          );
        }}
      >
        Items without category
      </Tab>
      <Tab
        active={ui.table === 6}
        onClick={(_) => {
          if (ui.table !== 6) {
            dispatch(setCurrentTable(6));
          }
          console.log("Report Retail click")
          dispatch(databaseActions.setShopReport([]));
          dispatch(
            databaseActions.getReportsXX(
              database.currentCompany.id,
              userState.user.user_id,
              database.currentShop.shop_id
            )
          );
        }}
      >
        Report Retail
        
        
      </Tab>

      <Tab
        active={ui.table === 7}
        onClick={(_) => {
          if (ui.table !== 7) {
            dispatch(setCurrentTable(7));
          }
          dispatch(databaseActions.setShopTaxes([]));
          dispatch(
            databaseActions.getShopTaxes(
              database.currentCompany.id,
              userState.user.user_id,
              database.currentShop.shop_id
            )
          );
        }}
      >
        Taxes
      </Tab>

    </TabContainer>
  );
  const areaTabContainer = (
    <TabContainer>
      <Tab
        active={ui.table === 0}
        onClick={(_) => {
          if (ui.table !== 0) {
            dispatch(setCurrentTable(0));
          }
          dispatch(databaseActions.setAreaPayments([]));
          dispatch(
            databaseActions.getAreaPayments(
              userState.user.user_id,
              database.currentCompany.direct
                ? database.currentShop.companyid
                : database.currentCompany.id,
              database.currentCompany.direct ? 0 : database.currentShop.area_id
            )
          );
        }}
      >
        Payments
      </Tab>
      <Tab
        active={ui.table === 1}
        onClick={(_) => {
          if (ui.table !== 1) {
            dispatch(setCurrentTable(1));
          }
          dispatch(databaseActions.setAreaCategories([]));
          dispatch(
            databaseActions.getAreaCategories(
              userState.user.user_id,
              database.currentCompany.direct
                ? database.currentShop.companyid
                : database.currentCompany.id,
              database.currentCompany.direct ? 0 : database.currentShop.area_id
            )
          );
        }}
      >
        Categories
      </Tab>
      <Tab
        active={ui.table === 4}
        onClick={(_) => {
          if (ui.table !== 4) {
            dispatch(setCurrentTable(4));
          }
          dispatch(databaseActions.setAreaCategoriesOther([]));
          dispatch(
            databaseActions.getAreaCategoriesOther(
              userState.user.user_id,
              database.currentCompany.direct
                ? database.currentShop.companyid
                : database.currentCompany.id,
              database.currentCompany.direct ? 0 : database.currentShop.area_id
            )
          );
        }}
      >
        Other (Over/Short,Tips) resto
      </Tab>
      <Tab
        active={ui.table === 7}
        onClick={(_) => {
          if (ui.table !== 7) {
            dispatch(setCurrentTable(7));
          }
          dispatch(databaseActions.setAreaTaxes([]));
          dispatch(
            databaseActions.getAreaTaxes(
              userState.user.user_id,
              database.currentCompany.direct
                ? database.currentShop.companyid
                : database.currentCompany.id,
              database.currentCompany.direct ? 0 : database.currentShop.area_id
            )
          );
        }}
      >
        Taxes (Resto)
      </Tab>
    </TabContainer>
  );

  return (
    <Card
      className={`categories-bar ${
        ui.theme === "flame" ? "" : "categories-bar-dark"
      }`}
    >
      {database.currentCompany.type === "RETAIL"
        ? shopTabContainer
        : areaTabContainer}
      <div style={{ flex: "1" }} />
      {database.currentCompany.type === "RETAIL" && ui.table === 1 && (
        <Button
          style={{ marginRight: "8px" }}
          size="small"
          fill="true"
          onClick={() => dispatch(toggleExtend())}
        >
          Extend
        </Button>
      )}
      <div style={{ marginRight: "8px" }}>
        <Input
          value={ui.search}
          onChange={(e) => dispatch(setSearch(e.target.value))}
          type="text"
          size="small"
          placeholder="Search"
          width="250px"
        />
      </div>
    </Card>
  );
};

export default CategorieBar;
