import React, { useState, useEffect } from "react";

import { Td, Tr } from "@lightspeed/cirrus-table";

import { Button } from "@lightspeed/flame/Button";
import { Input } from "@lightspeed/flame/Input";

import { useDispatch, useSelector } from "react-redux";
import * as databaseActions from "../../../redux/actions/databaseActions";
const Row = ({ item }) => {
  const dispatch = useDispatch();
  const ui = useSelector((state) => state.ui);
  const userState = useSelector((state) => state.userState);
  const database = useSelector((state) => state.database);
  const [edit, setEdit] = useState(false);
  const [changed, setChanged] = useState(false);
  const [inputBox1, setInputBox1] = useState("");
  const [inputBox2, setInputBox2] = useState("");
  const normalAccount = item.account === null ? "" : item.account;
  const normalAccountDescription =
    item.account_description === null ? "" : item.account_description;

  useEffect(() => {
    setInputBox1(normalAccount);
    setInputBox2(normalAccountDescription);
    if (normalAccount === "" && normalAccountDescription === "") {
      setEdit(true);
    } else {
      setEdit(false);
    }
  }, [normalAccount, normalAccountDescription]);
  useEffect(() => {
    if (normalAccount === inputBox1 && normalAccountDescription === inputBox2) {
      setChanged(false);
    } else {
      setChanged(true);
    }
  }, [inputBox1, inputBox2, normalAccount, normalAccountDescription]);

  const handleUpdate = () => {
    dispatch(
      databaseActions.updateRetailCategoryRound(
        userState.user.user_id,
        item.company_id,
        database.currentShop.shop_id,
        inputBox1,
        inputBox2,
        item.categoryid,
        databaseActions.getShopCategoriesRound(
          database.currentCompany.id,
          userState.user.user_id,
          database.currentShop.shop_id
        )
      )
    );
    setEdit(false);
  };

  return (
    <Tr
      textAlign="right"
      className={`${ui.theme === "flame" ? "" : "dark-table-row"}`}
      onDoubleClick={() => {
        setInputBox1(normalAccount);
        setInputBox2(normalAccountDescription);
        setEdit(true);
      }}
    >
      <Td width="10%">{item.company_id}</Td>
      <Td width="10%">{item.categoryid}</Td>
      <Td width="30%">{item.category_name}</Td>
      <Td width="10%">{item.parentid}</Td>
      <Td width="15%">
        {!edit && (
          <div className="row-input-wrapper">
            {inputBox1}
            <div className="flex" />
          </div>
        )}

        {edit && (
          <div className="row-input-wrapper">
            <div>
              <Input
                className="input-wrapper-row-inputbox"
                type="text"
                size="small"
                value={inputBox1}
                onChange={(e) => setInputBox1(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleUpdate();
                  }
                }}
              />
            </div>
          </div>
        )}
      </Td>

      <Td width="20%">
        {!edit && (
          <div className="row-input-wrapper">
            {inputBox2}
            <div className="flex" />
          </div>
        )}

        {edit && (
          <div className="row-input-wrapper">
            <div>
              <Input
                className="input-wrapper-row-inputbox"
                type="text"
                size="small"
                value={inputBox2}
                onChange={(e) => setInputBox2(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleUpdate();
                  }
                }}
              />
            </div>
          </div>
        )}
      </Td>
      <Td width="10%" textAlign="right">
        {!edit && (
          <div className="row-input-wrapper-end">
            <Button
              onClick={(_) => {
                setInputBox1(normalAccount);
                setInputBox2(normalAccountDescription);
                setEdit(true);
              }}
              size="small"
              fill="true"
            >
              Edit
            </Button>
          </div>
        )}

        {edit && (
          <div className="row-input-wrapper-end">
            {changed ? (
              <Button onClick={handleUpdate} size="small" fill="true">
                Update
              </Button>
            ) : inputBox1 === "" && inputBox2 === "" ? null : (
              <Button
                onClick={(_) => {
                  setInputBox1(normalAccount);
                  setInputBox2(normalAccountDescription);
                  setEdit(false);
                }}
                size="small"
                fill="true"
              >
                Cancel
              </Button>
            )}
          </div>
        )}
      </Td>
    </Tr>
  );
};

export default Row;
