import React from "react";
import { nanoid } from "nanoid";
import { useSelector } from "react-redux";

import { Card } from "@lightspeed/flame/Card";
import arrow from "../../../assets/arrow.png";

import SideListItem from "./sideList/SideListItem";

const SideList = ({
  label,
  list,
  listN,
  openList,
  setOpenList,
  setDashMain,
}) => {
  const ui = useSelector((state) => state.ui);
  const database = useSelector((state) => state.database);

  return (
    <div className="side-list-wrapper">
      <div
        onClick={(_) => {
          if (openList.current === listN) {
            setOpenList({ current: 0 });
          } else {
            setOpenList({ current: listN });
          }
        }}
        className={`side-label ${listN === openList.current ? "rotate" : ""}`}
      >
        <p>{label}</p>
        <img src={arrow} alt="arrow" />
      </div>

      <Card
        className={`side-list-card ${ui.theme === "flame" ? "" : "dark2"} ${
          openList.current === listN ? "expand-side-list" : ""
        }`}
      >
        {list.length === 0 && (
          <SideListItem
            item={{ companyid: 1, company_name: "no items" }}
            setDashMain={setDashMain}
          />
        )}

        {list.map((item) => (
          <SideListItem
            current={database.currentCompany.id}
            key={nanoid()}
            item={item}
            listN={listN}
            setDashMain={setDashMain}
          />
        ))}
      </Card>
    </div>
  );
};

export default SideList;
