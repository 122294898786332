import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import { Card } from "@lightspeed/flame/Card";
import { Input } from "@lightspeed/flame/Input";
import { Button } from "@lightspeed/flame/Button";

import { setFiltered } from "../../redux/actions/databaseActions";

const LocationTopBar = ({ setExtended, extended }) => {
  const dispatch = useDispatch();
  const ui = useSelector((state) => state.ui);
  const database = useSelector((state) => state.database);
  const [value, setValue] = useState("");
  const handleClick = () => {
    setExtended((oldState) => !oldState);
  };
  const handleChange = (e) => {
    setValue(e.target.value);
  };
  useEffect(() => {
    dispatch(setFiltered(value));
  }, [value, dispatch]);
  return (
    <Card
      top="true"
      className={`location-top-bar ${
        ui.theme === "flame" ? "" : "location-top-bar-dark"
      }`}
    >
      <div className="flex" />
      {database.currentCompany.shops.length > 7 && (
        <Button size="small" type="submit" fill="true" onClick={handleClick}>
          {extended ? "Collapse Locations" : "Extend Locations"}
        </Button>
      )}
      <div className="input-wrapper">
        <Input
          className="input-custom-bg"
          value={value}
          onChange={handleChange}
          type="text"
          size="small"
          placeholder="Seach by Location"
        />
      </div>
    </Card>
  );
};

export default LocationTopBar;
