import React from "react";
import { Divider } from "@lightspeed/flame/Divider";

import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import { setCurrentTable } from "../../../../redux/actions/uiActions";

import * as databaseActions from "../../../../redux/actions/databaseActions";

const SideListItem = ({ item, listN, setDashMain }) => {
  const database = useSelector((state) => state.database);
  const userState = useSelector((state) => state.userState);
  const ui = useSelector((state) => state.ui);
  const dispatch = useDispatch();
  const { area_visible, companyid, company_name } = item;

  const dynamicClass =
  
    listN === 1
      ? companyid === database.currentCompany.id
        ? "side-list-active"
        : ""
      : area_visible
      ? companyid === database.currentCompany.id
        ? "side-list-active"
        : ""
      : companyid === database.currentShop.companyid
      ? "side-list-active"
      : "";

  return (
    <div
      onClick={(_) => {
        setDashMain(false);

        console.log("listN ->", listN )

        if (listN === 1) {
          dispatch(databaseActions.setCurrentCompanyId(companyid));
          dispatch(databaseActions.getCurrentRetail(companyid));
        }
        if (listN === 2) {
          if (ui.table === 2 || ui.table === 3 || ui.table === 5) {
            dispatch(setCurrentTable(0));
          }

          if (area_visible) {
            dispatch(databaseActions.setCurrentCompanyId(companyid));
            dispatch(databaseActions.setCurrentRestoDirect(false));
          } else {
            dispatch(databaseActions.setCurrentCompanyType("RESTO"));
            dispatch(databaseActions.setCurrentShopCompanyId(companyid));
            dispatch(databaseActions.setCurrentRestoDirect(true));
          }

          dispatch(
            databaseActions.getCurrentResto(
              userState.user.id,
              companyid,
              area_visible
            )
          );
        }
      }}
      className={`side-list ${dynamicClass}`}
    >
      <p>{company_name}</p>
      <Divider />
    </div>
  );
};

export default SideListItem;
