import React, { useState, useEffect } from "react";

import { Table, Thead, Tr, Th, Sortable } from "@lightspeed/cirrus-table";
import { Card } from "@lightspeed/flame/Card";

import Row from "./paymentTable/Row";
import Loader from "../Loader";

import { useSelector } from "react-redux";

import * as sortFns from "../../utils/sortFn";

const PaymentTable = ({ data, scrollPos, sortState, changeSortState }) => {
  const ui = useSelector((state) => state.ui);
  const [sortedData, setSortedData] = useState([...data]);
  const sortValues = ["unsorted", "desc", "asc"];
  const reducer = (accumulator, currentValue) => accumulator + currentValue;

  useEffect(() => {
    setSortedData([...data]);
  }, [data]);

  useEffect(() => {
    if (sortState.reduce(reducer) > 0) {
      // company id
      if (sortState[0] === 1) {
        setSortedData((oldSortedData) => [
          ...oldSortedData.sort(sortFns.customSortGen("companyid")),
        ]);
      } else if (sortState[0] === 2) {
        setSortedData((oldSortedData) => [
          ...oldSortedData.sort(sortFns.customSortGen("companyid")).reverse(),
        ]);
      }

      // payment id
      if (sortState[1] === 1) {
        setSortedData((oldSortedData) => [
          ...oldSortedData.sort(sortFns.customSortGen("paymenttypeid")),
        ]);
      } else if (sortState[1] === 2) {
        setSortedData((oldSortedData) => [
          ...oldSortedData
            .sort(sortFns.customSortGen("paymenttypeid"))
            .reverse(),
        ]);
      }

      // payment name
      if (sortState[2] === 1) {
        setSortedData((oldSortedData) => [
          ...oldSortedData.sort(
            sortFns.customSortGenString("paymenttypeid_name")
          ),
        ]);
      } else if (sortState[2] === 2) {
        setSortedData((oldSortedData) => [
          ...oldSortedData
            .sort(sortFns.customSortGenString("paymenttypeid_name"))
            .reverse(),
        ]);
      }

      // account
      if (sortState[3] === 1) {
        setSortedData((oldSortedData) => [
          ...oldSortedData.sort(sortFns.customSortGenString("account")),
        ]);
      } else if (sortState[3] === 2) {
        setSortedData((oldSortedData) => [
          ...oldSortedData
            .sort(sortFns.customSortGenString("account"))
            .reverse(),
        ]);
      }

      // account descrition
      if (sortState[4] === 1) {
        setSortedData((oldSortedData) => [
          ...oldSortedData.sort(
            sortFns.customSortGenString("account_description")
          ),
        ]);
      } else if (sortState[4] === 2) {
        setSortedData((oldSortedData) => [
          ...oldSortedData
            .sort(sortFns.customSortGenString("account_description"))
            .reverse(),
        ]);
      }
    } else {
      setSortedData([...data]);
    }
  }, [sortState, data]);

  if (sortedData.length === 0 && ui.search) {
    return (
      <div className="loader-no-item">
        <h3>No items match your search</h3>
      </div>
    );
  }

  if (!sortedData || sortedData.length === 0 || !Array.isArray(sortedData)) {
    return <Loader />;
  }

  return (
    <Card top="true">
      <Table>
        <Thead>
          {scrollPos === true ? (
            <Tr className={`${ui.theme === "flame" ? "" : "dark-table"}`}>
              <Sortable.Th
                width="15%"
                direction={sortValues[sortState[0]]}
                onClick={() => {
                  changeSortState(0);
                }}
              >
                Company
              </Sortable.Th>
              <Sortable.Th
                width="15%"
                direction={sortValues[sortState[1]]}
                onClick={() => {
                  changeSortState(1);
                }}
              >
                Payment Id
              </Sortable.Th>
              <Sortable.Th
                width="20%"
                direction={sortValues[sortState[2]]}
                onClick={() => {
                  changeSortState(2);
                }}
              >
                Payment Name
              </Sortable.Th>
              <Sortable.Th
                width="20%"
                direction={sortValues[sortState[3]]}
                onClick={() => {
                  changeSortState(3);
                }}
              >
                Account Id
              </Sortable.Th>
              <Sortable.Th
                width="20%"
                direction={sortValues[sortState[4]]}
                onClick={() => {
                  changeSortState(4);
                }}
              >
                Account Description
              </Sortable.Th>
              <Th width="10%" textAlign="right">
                Action
              </Th>
            </Tr>
          ) : (
            <Tr className={`${ui.theme === "flame" ? "" : "dark-table-fake"}`}>
              <Th className="table-th-sticky" width="15%">
                Company
              </Th>
              <Th className="table-th-sticky" width="15%">
                Payment Id
              </Th>
              <Th className="table-th-sticky" width="20%">
                Payment Name
              </Th>
              <Th className="table-th-sticky" width="20%">
                Account Id
              </Th>
              <Th className="table-th-sticky" width="20%">
                Account Description
              </Th>
              <Th className="table-th-sticky" width="10%" textAlign="right">
                Action
              </Th>
            </Tr>
          )}
        </Thead>
        <tbody className="t-body">
          {sortedData.map((item) => (
            <Row key={item.paymenttypeid} item={item} />
          ))}
        </tbody>
      </Table>
    </Card>
  );
};

export default PaymentTable;
