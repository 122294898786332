import axios from "axios";
import { setNotify } from "./uiActions";
import { serverUrl } from "../../serverUrl";
import { setBusy } from "./uiActions";

export const getRetail = (token_user_id) => {
  return (dispatch) => {
    const config = {
      method: "post",
      url: `${serverUrl}/api/list/retail`,
      headers: {
        "Content-Type": "application/json",
      },
      data: { token_user_id },
    };

    axios(config)
      .then((resp) => {
        dispatch(setRetail(resp.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const getResto = (token_user_id) => {
  return (dispatch) => {
    const config = {
      method: "post",
      url: `${serverUrl}/api/list/resto`,
      headers: {
        "Content-Type": "application/json",
      },
      data: { token_user_id },
    };

    axios(config)
      .then((resp) => {
        dispatch(setResto(resp.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const getCurrentRetail = (token_company_id) => {
  

  return (dispatch, getState) => {

    //console.log("RETAIL2 --->>> ",getState().ui.table)
    const config = {
      method: "post",
      url: `${serverUrl}/api/list/retail/shops`,
      headers: {
        "Content-Type": "application/json",
      },
      data: { token_company_id },
    };

    axios(config)
      .then((resp) => {
        dispatch(setCurrentRetail(resp.data, token_company_id));
        switch (getState().ui.table) {
          case 1:
            dispatch(setShopCategories([]));
            dispatch(
              getShopCategories(
                token_company_id,
                getState().userState.user.user_id,
                resp.data[0].shop_id
              )
            );
            break;
          case 2:
            dispatch(
              getShopCategoriesMulti(
                token_company_id,
                getState().userState.user.user_id,
                resp.data[0].shop_id
              )
            );
            break;
          case 3:
            dispatch(
              getShopCategoriesRound(
                token_company_id,
                getState().userState.user.user_id,
                resp.data[0].shop_id
              )
            );
            break;
          case 4:
            dispatch(
              getShopCategoriesOther(token_company_id, resp.data[0].shop_id)
            );
            break;
          case 5:
            dispatch(
              getShopCategoriesLess(
                token_company_id,
                getState().userState.user.user_id,
                resp.data[0].shop_id
              )
            );
          case 6:
            dispatch(
              getReport(
                token_company_id,
                getState().userState.user.user_id,
                resp.data[0].shop_id
              )
            );

          /*  
          case 7:
              
              dispatch(setShopCategories([]));
              dispatch(
                getShopCategories(
                  token_company_id,
                  getState().userState.user.user_id,
                  resp.data[0].shop_id
                )
              );
              break;
          */
         
          case 7:
              dispatch(setShopTaxes([]));
              dispatch(
                getShopTaxes(
                  token_company_id,
                  getState().userState.user.user_id,
                  resp.data[0].shop_id
                )
              );
            break;
          default:
            dispatch(
              getShopPayments(
                token_company_id,
                getState().userState.user.user_id,
                resp.data[0].shop_id
              )
            );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const getShopPayments = (
  token_company_id,
  token_user_id,
  token_shop_id
) => {
  return (dispatch) => {
    const config = {
      method: "post",
      url: `${serverUrl}/api/list/retail/payments`,
      headers: {
        "Content-Type": "application/json",
      },
      data: { token_company_id, token_user_id, token_shop_id },
    };

    axios(config)
      .then((resp) => {
        dispatch(setBusy(true));
        dispatch(setShopPayments(resp.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const getShopTaxes = (
  token_company_id,
  token_user_id,
  token_shop_id
) => {
  return (dispatch) => {
    const config = {
      method: "post",
      url: `${serverUrl}/api/list/retail/taxes`,
      headers: {
        "Content-Type": "application/json",
      },
      data: { token_company_id, token_user_id, token_shop_id },
    };

    axios(config)
      .then((resp) => {
        dispatch(setBusy(true));
        dispatch(setShopTaxes(resp.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const getShopCategories = (
  token_company_id,
  token_user_id,
  token_shop_id
) => {
  return (dispatch) => {
    const config = {
      method: "post",
      url: `${serverUrl}/api/list/retail/categories`,
      headers: {
        "Content-Type": "application/json",
      },
      data: { token_company_id, token_user_id, token_shop_id },
    };

    axios(config)
      .then((resp) => {
        dispatch(setBusy(true));
        dispatch(setShopCategories(resp.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const getShopCategoriesMulti = (
  token_company_id,
  token_user_id,
  token_shop_id
) => {
  return (dispatch) => {
    const config = {
      method: "post",
      url: `${serverUrl}/api/list/retail/categoriesmulti`,
      headers: {
        "Content-Type": "application/json",
      },
      data: { token_company_id, token_user_id, token_shop_id },
    };

    axios(config)
      .then((resp) => {
        dispatch(setBusy(true));
        dispatch(setShopCategoriesMulti(resp.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const getShopCategoriesMultiFree = (token_company_id, token_user_id) => {
  return (dispatch) => {
    const config = {
      method: "post",
      url: `${serverUrl}/api/retail/categoriesmultifree`,
      headers: {
        "Content-Type": "application/json",
      },
      data: { token_company_id, token_user_id, token_category_id: 0 },
    };

    axios(config)
      .then((resp) => {
        dispatch(setBusy(true));
        dispatch(setShopCategoriesMultiFree(resp.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const addShopCategoriesMulti = (
  token_company_id,
  token_user_id,
  token_shop_id,
  token_category_id,
  callback
) => {
  return (dispatch) => {
    const config = {
      method: "post",
      url: `${serverUrl}/api/retail/categoriesmulticreate`,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        token_company_id,
        token_user_id,
        token_shop_id,
        token_category_id,
      },
    };

    axios(config)
      .then(() => {
        dispatch(setBusy(true));
        dispatch(callback(token_company_id, token_user_id, token_shop_id));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const updateShopCategoriesMulti = (
  token_company_id,
  token_user_id,
  token_shop_id,
  token_category_id,
  token_multi_id,
  token_account_id,
  token_account_name,
  token_amount,
  callback
) => {
  return (dispatch) => {
    const config = {
      method: "put",
      url: `${serverUrl}/api/retail/categoriesmultiupdate`,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        token_company_id,
        token_user_id,
        token_shop_id,
        token_category_id,
        token_multi_id,
        token_account_id,
        token_account_name,
        token_amount,
      },
    };

    axios(config)
      .then(() => {
        dispatch(setBusy(true));
        dispatch(callback(token_company_id, token_user_id, token_shop_id));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const removeShopCategoriesMulti = (
  token_company_id,
  token_user_id,
  token_shop_id,
  token_category_id,
  callback
) => {
  return (dispatch) => {
    const config = {
      method: "post",
      url: `${serverUrl}/api/retail/categoriesmultidelete`,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        token_company_id,
        token_user_id,
        token_shop_id,
        token_category_id,
      },
    };

    axios(config)
      .then(() => {
        dispatch(setBusy(true));
        dispatch(callback(token_company_id, token_user_id, token_shop_id));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const getShopCategoriesRound = (
  token_company_id,
  token_user_id,
  token_shop_id
) => {
  return (dispatch) => {
    const config = {
      method: "post",
      url: `${serverUrl}/api/list/retail/categoriesround`,
      headers: {
        "Content-Type": "application/json",
      },
      data: { token_company_id, token_user_id, token_shop_id },
    };

    axios(config)
      .then((resp) => {
        dispatch(setBusy(true));
        dispatch(setShopCategoriesRound(resp.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const getShopCategoriesOther = (token_company_id, token_shop_id) => {
  return (dispatch) => {
    const config = {
      method: "post",
      url: `${serverUrl}/api/list/retail/other`,
      headers: {
        "Content-Type": "application/json",
      },
      data: { token_company_id, token_shop_id },
    };

    axios(config)
      .then((resp) => {
        dispatch(setBusy(true));
        dispatch(setShopCategoriesOther(resp.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const getShopCategoriesLess = (
  token_company_id,
  token_user_id,
  token_shop_id
) => {
  return (dispatch) => {
    const config = {
      method: "post",
      url: `${serverUrl}/api/list/retail/items`,
      headers: {
        "Content-Type": "application/json",
      },
      data: { token_company_id, token_user_id, token_shop_id },
    };

    axios(config)
      .then((resp) => {
        dispatch(setBusy(true));
        dispatch(setShopCategoriesLess(resp.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};


export const getReports = (
  token_company_id,
  token_user_id,
  token_shop_id,
  date_from,
  date_to
) => {
  return (dispatch) => {
    date_from = "2023-03-03";
    date_to = "2023-03-03";
    const config = {
      method: "post",
      url: `${serverUrl}/api/list/retail/report`,
      headers: {
        "Content-Type": "application/json",
      },
      data: { token_company_id, token_user_id, token_shop_id, date_from, date_to },
    };

    
    axios(config)
      .then((resp) => {
        dispatch(setBusy(true));
        dispatch(setShopReport(resp.data));
        //console.log(resp.data)
      })
      .catch((err) => {
        console.log(err);
      });
  };
};


export const getReportsXX = (
  token_company_id,
  token_user_id,
  token_shop_id,
  date_from,
  date_to
) => {
  return (dispatch) => {
    date_from = "2023-03-03";
    date_to = "2023-03-03";
    const config = {
      method: "post",
      url: `${serverUrl}/api/list/retail/report`,
      headers: {
        "Content-Type": "application/json",
      },
      data: { token_company_id, token_user_id, token_shop_id, date_from, date_to },
    };

    axios(config)
      .then((resp) => {
        dispatch(setBusy(true));
        //dispatch(setShopReport(resp.data));
        const data  = [{"transaction_date":"2023-05-06"}]
        // console.log("DATAXX1->", data)
        return '[{"transaction_date":"2023-05-06"}]';
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const getCurrentResto = (
  token_user_id,
  token_company_id,
  area_visible
) => {
  return (dispatch, getState) => {
    const url = `${serverUrl}/api/list/resto/areas`;
    const data = { token_user_id, token_company_id };
    const config = {
      method: "post",
      url,
      headers: {
        "Content-Type": "application/json",
      },
      data,
    };


    if (area_visible === 1) {
      axios(config)
        .then((resp) => {
          dispatch(setCurrentResto(resp.data, token_company_id));
          switch (getState().ui.table) {
            case 1:
              dispatch(
                getAreaCategories(
                  getState().userState.user.user_id,
                  token_company_id,
                  resp.data[0].area_id
                )
              );
              break;
            case 4:
              dispatch(
                getAreaCategoriesOther(
                  getState().userState.user.user_id,
                  token_company_id,
                  resp.data[0].area_id
                )
              );
              break;

            case 7:
                dispatch(
                  getAreaTaxes(
                    getState().userState.user.user_id,
                    token_company_id,
                    resp.data[0].area_id
                  )
                );
                break;
            default:
              dispatch(
                getAreaPayments(
                  getState().userState.user.user_id,
                  token_company_id,
                  resp.data[0].area_id
                )
              );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      switch (getState().ui.table) {
        
        case 1:
          
          dispatch(
            getAreaCategories(
              getState().userState.user.user_id,
              token_company_id,
              0
            )
          );
          break;
        case 4:
          dispatch(
            getAreaCategoriesOther(
              getState().userState.user.user_id,
              token_company_id,
              0
            )
          );
          break;
        case 7:
              
            dispatch(
              getAreaTaxes(
                getState().userState.user.user_id,
                token_company_id,
                0
              )
            );
 
            break;
        default:
          dispatch(
            getAreaPayments(
              getState().userState.user.user_id,
              token_company_id,
              0
            )
          );
      }
    }
  };
};
export const getCurrentRetailShop = (token_company_id, token_shop_id) => {
  

  return (dispatch, getState) => {
    
    console.log("RETAIL1 --->>> ",getState().ui.table)


    switch (getState().ui.table) {
      case 1:
        dispatch(setShopCategories([]));
        dispatch(
          getShopCategories(
            token_company_id,
            getState().userState.user.user_id,
            token_shop_id
          )
        );
        break;
      case 2:
        dispatch(
          getShopCategoriesMulti(
            token_company_id,
            getState().userState.user.user_id,
            token_shop_id
          )
        );
        break;
      case 3:
        dispatch(
          getShopCategoriesRound(
            token_company_id,
            getState().userState.user.user_id,
            token_shop_id
          )
        );
        break;
      case 4:
        dispatch(getShopCategoriesOther(token_company_id, token_shop_id));
        break;

      case 6:
        dispatch(
          getShopCategoriesLess(
            token_company_id,
            getState().userState.user.user_id,
            token_shop_id
          )
        );
        break;
      
        /*
        case 7:
          dispatch(
            getShopCategoriesLess(
              token_company_id,
              getState().userState.user.user_id,
              token_shop_id
            )
          );
          break;  
        */
        case 7:
          dispatch(
            getShopTaxes(
              token_company_id,
              getState().userState.user.user_id,
              token_shop_id
            )
          );
        break;
      default:
        dispatch(
          getShopPayments(
            token_company_id,
            getState().userState.user.user_id,
            token_shop_id
          )
        );
    }
  };
};
export const getCurrentRestoArea = (token_company_id, token_area_id) => {
  return (dispatch, getState) => {
    switch (getState().ui.table) {
      case 1:
        dispatch(
          getAreaCategories(
            getState().userState.user.user_id,
            token_company_id,
            token_area_id
          )
        );
        break;
      case 4:
        dispatch(
          getAreaCategoriesOther(
            getState().userState.user.user_id,
            token_company_id,
            token_area_id
          )
        );
        break;
      case 7:
        dispatch(
          getAreaTaxes(
            getState().userState.user.user_id,
            token_company_id,
            token_area_id
          )
        );
        break;
      default:
        dispatch(
          getAreaPayments(
            getState().userState.user.user_id,
            token_company_id,
            token_area_id
          )
        );
    }
  };
};

export const getAreaPayments = (
  token_user_id,
  token_company_id,
  token_area_id
) => {
  return (dispatch) => {
    const config = {
      method: "post",
      url: `${serverUrl}/api/list/resto/payments`,
      headers: {
        "Content-Type": "application/json",
      },
      data: { token_user_id, token_company_id, token_area_id },
    };
    axios(config)
      .then((resp) => {
        dispatch(setBusy(true));
        dispatch(setAreaPayments(resp.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const getAreaTaxes = (
  token_user_id,
  token_company_id,
  token_area_id
) => {
  //console.log(333)
  return (dispatch) => {
    const config = {
      method: "post",
      url: `${serverUrl}/api/list/resto/taxex`,
      headers: {
        "Content-Type": "application/json",
      },
      data: { token_user_id, token_company_id, token_area_id },
    };
    axios(config)
      .then((resp) => {
        dispatch(setBusy(true));
        dispatch(setAreaTaxes(resp.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const getAreaCategories = (
  token_user_id,
  token_company_id,
  token_area_id
) => {
  return (dispatch) => {
    const config = {
      method: "post",
      url: `${serverUrl}/api/list/resto/categories`,
      headers: {
        "Content-Type": "application/json",
      },
      data: { token_user_id, token_company_id, token_area_id },
    };

    axios(config)
      .then((resp) => {
        dispatch(setBusy(true));
        dispatch(setAreaCategories(resp.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const getAreaCategoriesOther = (
  token_user_id,
  token_company_id,
  token_area_id
) => {
  return (dispatch) => {
    const config = {
      method: "post",
      url: `${serverUrl}/api/list/resto/other`,
      headers: {
        "Content-Type": "application/json",
      },
      data: { token_user_id, token_company_id, token_area_id },
    };

    axios(config)
      .then((resp) => {
        dispatch(setBusy(true));
        dispatch(setAreaCategoriesOther(resp.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const updateRetailTaxes = (
  token_user_id,
  token_company_id,
  token_shop_id,
  token_account_id,
  token_account_name,
  token_payment_id,
  dispatch_on_done
) => {
  return (dispatch) => {
    const config = {
      method: "put",
      url: `${serverUrl}/api/retail/taxesupdate`,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        token_user_id,
        token_company_id,
        token_shop_id,
        token_account_id,
        token_account_name,
        token_payment_id,
      },
    };

    axios(config)
      .then((res) => {
        dispatch(setNotify(false, res));
        dispatch(dispatch_on_done);
      })
      .catch((err) => {
        dispatch(setNotify(true, err));
        dispatch(dispatch_on_done);
      });
  };
};

export const updateRetailPayment = (
  token_user_id,
  token_company_id,
  token_shop_id,
  token_account_id,
  token_account_name,
  token_payment_id,
  dispatch_on_done
) => {
  return (dispatch) => {
    const config = {
      method: "put",
      url: `${serverUrl}/api/retail/paymentsupdate`,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        token_user_id,
        token_company_id,
        token_shop_id,
        token_account_id,
        token_account_name,
        token_payment_id,
      },
    };

    axios(config)
      .then((res) => {
        dispatch(setNotify(false, res));
        dispatch(dispatch_on_done);
      })
      .catch((err) => {
        dispatch(setNotify(true, err));
        dispatch(dispatch_on_done);
      });
  };
};
export const updateRetailCategory = (
  token_user_id,
  token_company_id,
  token_shop_id,
  token_account_id,
  token_account_name,
  token_category_id,
  token_flash,
  token_cogs,
  token_cogs_description,
  dispatch_on_done
) => {
  return (dispatch) => {
    const config = {
      method: "put",
      url: `${serverUrl}/api/retail/categoriesupdate`,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        token_company_id,
        token_user_id,
        token_category_id,
        token_account_id,
        token_account_name,
        token_shop_id,
        token_flash,
        token_cogs,
        token_cogs_description,
      },
    };

    axios(config)
      .then((res) => {
        dispatch(setNotify(false, res));
        dispatch(dispatch_on_done);
      })
      .catch((err) => {
        dispatch(setNotify(true, err));
        dispatch(dispatch_on_done);
      });
  };
};
export const updateRetailCategoryRound = (
  token_user_id,
  token_company_id,
  token_shop_id,
  token_account_id,
  token_account_name,
  token_category_id,
  dispatch_on_done
) => {
  return (dispatch) => {
    const config = {
      method: "put",
      url: `${serverUrl}/api/retail/categoriesroundupdate`,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        token_user_id,
        token_company_id,
        token_shop_id,
        token_account_id,
        token_account_name,
        token_category_id,
      },
    };

    axios(config)
      .then((res) => {
        dispatch(setNotify(false, res));
        dispatch(dispatch_on_done);
      })
      .catch((err) => {
        dispatch(setNotify(true, err));
        dispatch(dispatch_on_done);
      });
  };
};
export const updateRetailOther = (
  token_company_id,
  token_shop_id,
  token_what,
  token_code,
  token_code_name,
  dispatch_on_done
) => {
  return (dispatch) => {
    const config = {
      method: "put",
      url: `${serverUrl}/api/retail/otherupdate`,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        token_company_id,
        token_shop_id,
        token_what,
        token_code,
        token_code_name,
      },
    };

    axios(config)
      .then((res) => {
        dispatch(setNotify(false, res));
        dispatch(dispatch_on_done);
      })
      .catch((err) => {
        dispatch(setNotify(true, err));
        dispatch(dispatch_on_done);
      });
  };
};
export const updateRestoPayments = (
  token_user_id,
  token_company_id,
  token_payment_id,
  token_account_id,
  token_account_name,
  token_area_id,
  dispatch_on_done
) => {
  return (dispatch) => {
    const config = {
      method: "put",
      url: `${serverUrl}/api/resto/paymentsupdate`,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        token_user_id,
        token_company_id,
        token_payment_id,
        token_account_id,
        token_account_name,
        token_area_id,
      },
    };

    axios(config)
      .then((res) => {
        dispatch(setNotify(false, res));
        dispatch(dispatch_on_done);
      })
      .catch((err) => {
        dispatch(setNotify(true, err));
        dispatch(dispatch_on_done);
      });
  };
};
export const updateRestoTaxes = (
  token_user_id,
  token_company_id,
  token_payment_id,
  token_account_id,
  token_account_name,
  token_area_id,
  dispatch_on_done
) => {
  return (dispatch) => {
    const config = {
      method: "put",
      url: `${serverUrl}/api/resto/taxessupdate`,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        token_user_id,
        token_company_id,
        token_payment_id,
        token_account_id,
        token_account_name,
        token_area_id,
      },
    };

    axios(config)
      .then((res) => {
        dispatch(setNotify(false, res));
        dispatch(dispatch_on_done);
      })
      .catch((err) => {
        dispatch(setNotify(true, err));
        dispatch(dispatch_on_done);
      });
  };
};
export const updateRestoCategories = (
  token_user_id,
  token_company_id,
  token_category_id,
  token_account_id,
  token_account_name,
  token_area_id,
  dispatch_on_done
) => {
  return (dispatch) => {
    const config = {
      method: "put",
      url: `${serverUrl}/api/resto/categoriesupdate`,
      headers: {
        "Content-Type": "application/json",
      },

      data: {
        token_user_id,
        token_company_id,
        token_category_id,
        token_account_id,
        token_account_name,
        token_area_id,
      },
    };

    axios(config)
      .then((res) => {
        dispatch(setNotify(false, res));
        dispatch(dispatch_on_done);
      })
      .catch((err) => {
        dispatch(setNotify(true, err));
        dispatch(dispatch_on_done);
      });
  };
};
export const updateRestoOther = (
  token_company_id,
  token_what,
  token_code,
  token_code_name,
  dispatch_on_done
) => {
  return (dispatch) => {
    const config = {
      method: "put",
      url: `${serverUrl}/api/resto/otherupdate`,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        token_company_id,
        token_what,
        token_code,
        token_code_name,
      },
    };

    axios(config)
      .then((res) => {
        dispatch(setNotify(false, res));
        dispatch(dispatch_on_done);
      })
      .catch((err) => {
        dispatch(setNotify(true, err));
        dispatch(dispatch_on_done);
      });
  };
};

const setRetail = (retail) => ({ type: "SET_RETAIL", payload: retail });
const setResto = (resto) => ({ type: "SET_RESTO", payload: resto });

const setCurrentRetail = (currentRetail, id) => ({
  type: "SET_CURRENT_RETAIL",
  payload: { currentRetail, id },
});
export const setShopPayments = (payments) => ({
  type: "SET_CURRENT_SHOP_PAYMENTS",
  payload: payments,
});
export const setShopTaxes = (taxes) => ({
  type: "SET_CURRENT_SHOP_TAXES",
  payload: taxes,
});
export const setShopCategories = (categories) => ({
  type: "SET_CURRENT_SHOP_CATEGORIES",
  payload: categories,
});
export const setShopCategoriesMulti = (multi) => ({
  type: "SET_CURRENT_SHOP_CATEGORIES_MULTI",
  payload: multi,
});
export const setShopCategoriesMultiFree = (free) => ({
  type: "SET_CURRENT_SHOP_CATEGORIES_MULTI_FREE",
  payload: free,
});
export const setShopCategoriesMultiAdd = (newItem) => ({
  type: "SET_CURRENT_SHOP_CATEGORIES_MULTI_ADD",
  payload: newItem,
});
export const setShopCategoriesRound = (categories) => ({
  type: "SET_CURRENT_SHOP_CATEGORIES_ROUND",
  payload: categories,
});
export const setShopCategoriesOther = (other) => ({
  type: "SET_CURRENT_SHOP_CATEGORIES_OTHER",
  payload: other,
});
export const setShopCategoriesLess = (less) => ({
  type: "SET_CURRENT_SHOP_CATEGORIES_LESS",
  payload: less,
});
export const setShopReport = (reports) => ({
  type: "SET_CURRENT_SHOP_REPORT",
  payload: reports,
});
export const setCurrentResto = (currentResto, id) => ({
  type: "SET_CURRENT_RESTO",
  payload: { currentResto, id },
});
export const setFiltered = (filtered) => ({
  type: "SET_CURRENT_FILTERED_RETAL_RESTO_SHOPS",
  payload: filtered,
});
export const setCurrentRestoDirect = (trueFalse) => ({
  type: "SET_CURRENT_RESTO_DIRECT",
  payload: trueFalse,
});
export const setCurrentCompanyId = (id) => ({
  type: "SET_CURRENT_COMPANY_ID",
  payload: id,
});
export const setCurrentShopId = (id) => ({
  type: "SET_CURRENT_SHOP_ID",
  payload: id,
});
export const setCurrentCompanyType = (value) => ({
  type: "SET_CURRENT_COMPANY_TYPE",
  payload: value,
});
export const setCurrentShopCompanyId = (id) => ({
  type: "SET_CURRENT_SHOP_COMPANY_ID",
  payload: id,
});
export const setCurrentAreaId = (id) => ({
  type: "SET_CURRENT_AREA_ID",
  payload: id,
});
export const setAreaPayments = (payments) => ({
  type: "SET_CURRENT_AREA_PAYMENTS",
  payload: payments,
});
export const setAreaTaxes = (taxes) => ({
  type: "SET_CURRENT_AREA_TAXES",
  payload: taxes,
});
export const setAreaCategories = (categories) => ({
  type: "SET_CURRENT_AREA_CATEGORIES",
  payload: categories,
});
export const setAreaCategoriesOther = (other) => ({
  type: "SET_CURRENT_AREA_CATEGORIES_OTHER",
  payload: other,
});
