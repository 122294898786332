import axios from "axios";
import { serverUrl } from "../../serverUrl";

import * as databaseActions from "../actions/databaseActions";

export const getUser = ({ username, password, setNotification }) => {
  return (dispatch) => {
    const config = {
      method: "post",
      url: `${serverUrl}/api/login`,
      headers: {
        "Content-Type": "application/json",
      },
      data: { username, password },
    };
    axios(config)
      .then((resp) => {
        dispatch(setUser(resp.data));
        dispatch(databaseActions.getRetail(resp.data.user_id));
        dispatch(databaseActions.getResto(resp.data.user_id));
      })
      .catch((err) => {
        console.log(err);
        setNotification({ show: true, msg: "Incorrect username or password" });
      });
  };
};

const setUser = (user) => ({ type: "SET_USER", payload: user });
