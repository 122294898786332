const initState = {
  user: {
    user: "",
    last_update: "2019-11-16 19:52:16.000000",
  },
};

const userReducer = (state = initState, action) => {
  switch (action.type) {
    case "SET_USER":
      return { ...state, user: action.payload };
    default:
      return state;
  }
};

export default userReducer;
