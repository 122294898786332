const customSortCompanyName = (a, b) => {
  const companyA = a.company_name.toLowerCase();
  const companyB = b.company_name.toLowerCase();

  let comparison = 0;
  if (companyA > companyB) {
    comparison = 1;
  } else if (companyA < companyB) {
    comparison = -1;
  }
  return comparison;
};

const initState = {
  retail: [],
  resto: [],
  currentCompany: { id: -1, shops: [], filteredShops: [], direct: false },
  currentShop: {
    payments: [],
    taxes: [],
    categories: [],
    categoriesMulti: [],
    reports: [],
    categoriesRound: [],
    less: [],
    other: [],
    type: "RETAIL",
  },
};

const databaseReducer = (state = initState, action) => {
  switch (action.type) {
    case "SET_RETAIL":
      return { ...state, retail: action.payload.sort(customSortCompanyName) };
    case "SET_RESTO":
      return { ...state, resto: action.payload.sort(customSortCompanyName) };
    case "SET_CURRENT_RETAIL":
      return {
        ...state,
        currentCompany: {
          shops: action.payload.currentRetail,
          filteredShops: action.payload.currentRetail,
          id: action.payload.id,
          direct: false,
          type: "RETAIL",
        },
        currentShop: {
          ...action.payload.currentRetail[0],
          payments: [],
          taxes: [],
          categories: [],
          categoriesMulti: [],
          reports: [],
          categoriesFree: [],
          categoriesRound: [],
          less: [],
          other: [],
        },
      };
    case "SET_CURRENT_RESTO":
      return {
        ...state,
        currentCompany: {
          direct: action.payload.currentResto.length === 0 ? true : false,
          shops: action.payload.currentResto,
          filteredShops: action.payload.currentResto,
          id: action.payload.id,
          type: "RESTO",
        },
        currentShop: {
          ...action.payload.currentResto[0],
          payments: [],
          taxes: [],
          categories: [],
          categoriesMulti: [],
          reports: [],
          categoriesRound: [],
          less: [],
          other: [],
        },
      };
    case "SET_CURRENT_FILTERED_RETAL_RESTO_SHOPS":
      return {
        ...state,
        currentCompany: {
          ...state.currentCompany,
          filteredShops: state.currentCompany.shops.filter((item) => {
            if (item.area_name) {
              return (
                item.area_name
                  .toLowerCase()
                  .indexOf(action.payload.toLowerCase()) > -1
              );
            } else {
              return (
                item.shop_name
                  .toLowerCase()
                  .indexOf(action.payload.toLowerCase()) > -1
              );
            }
          }),
        },
      };
    case "SET_CURRENT_RESTO_DIRECT":
      return {
        ...state,
        currentCompany: {
          ...state.currentCompany,
          direct: action.payload,
        },
      };
    case "SET_CURRENT_SHOP_PAYMENTS":
      return {
        ...state,
        currentShop: { ...state.currentShop, payments: action.payload },
      };
    case "SET_CURRENT_SHOP_TAXES":
        return {
          ...state,
          currentShop: { ...state.currentShop, taxes: action.payload },
        };
    case "SET_CURRENT_SHOP_REPORT":
          return {
            ...state,
            currentShop: { ...state.currentShop, reports: action.payload },
          };
    case "SET_CURRENT_SHOP_CATEGORIES":
      return {
        ...state,
        currentShop: { ...state.currentShop, categories: action.payload },
      };
    case "SET_CURRENT_SHOP_CATEGORIES_MULTI":
      return {
        ...state,
        currentShop: {
          ...state.currentShop,
          categoriesMulti: action.payload.cards,
        },
      };
    case "SET_CURRENT_SHOP_CATEGORIES_MULTI_FREE":
      return {
        ...state,
        currentShop: {
          ...state.currentShop,
          categoriesFree: action.payload,
        },
      };
    case "SET_CURRENT_SHOP_CATEGORIES_ROUND":
      return {
        ...state,
        currentShop: { ...state.currentShop, categoriesRound: action.payload },
      };
    case "SET_CURRENT_SHOP_CATEGORIES_OTHER":
      return {
        ...state,
        currentShop: { ...state.currentShop, other: action.payload },
      };
    case "SET_CURRENT_SHOP_CATEGORIES_LESS":
      return {
        ...state,
        currentShop: { ...state.currentShop, less: action.payload },
      };
    case "SET_CURRENT_AREA_PAYMENTS":
      return {
        ...state,
        currentShop: {
          ...state.currentShop,
          payments: action.payload,
        },
      };
    case "SET_CURRENT_AREA_TAXES":
        return {
          ...state,
          currentShop: {
            ...state.currentShop,
            taxes: action.payload,
          },
        };
    case "SET_CURRENT_AREA_CATEGORIES":
      return {
        ...state,
        currentShop: {
          ...state.currentShop,
          categories: action.payload,
        },
      };
    case "SET_CURRENT_AREA_CATEGORIES_OTHER":
      return {
        ...state,
        currentShop: {
          ...state.currentShop,
          other: action.payload,
        },
      };
    case "SET_CURRENT_COMPANY_ID":
      return {
        ...state,
        currentCompany: {
          ...state.currentCompany,
          id: action.payload,
        },
      };
    case "SET_CURRENT_SHOP_ID":
      return {
        ...state,
        currentShop: {
          ...state.currentShop,
          shop_id: action.payload,
        },
      };
    case "SET_CURRENT_COMPANY_TYPE":
      return {
        ...state,
        currentCompany: { ...state.currentCompany, type: action.payload },
      };
    case "SET_CURRENT_SHOP_COMPANY_ID":
      return {
        ...state,
        currentCompany: { ...state.currentCompany, id: action.payload },
        currentShop: {
          ...state.currentShop,
          companyid: action.payload,
        },
      };
    case "SET_CURRENT_AREA_ID":
      return {
        ...state,
        currentShop: {
          ...state.currentShop,
          area_id: action.payload,
        },
      };
    default:
      return state;
  }
};

export default databaseReducer;
