import { useSelector } from "react-redux";
import Table from "../ui/reports/Table";
import React, { useRef, useState, useEffect } from "react";
import {
  getReports, getReportsXX, getReportsXXX,
} from "../../../src/redux/actions/databaseActions";

import styled from "@emotion/styled";
import DatePicker from "../ui/reports/DatePicker";

const Wrapper = styled.div`
  display: flex;
  flex-grow: 1;
`;

const Reports = () => {
  const { reports } = 1


  //const rows = ["RevenueCenterId", "MealPeriod", "PaymentName","itemizer_name"];
  const rows = ['transaction_date','gl_number','gl_description','debit','credit','total'];
  const cols = []
  const aggregatorName = ["Sum"];
  const vals = ['TotalAmount'];
  const rendererName = 'Table';
 
  const XX =  [{"transaction_date":"2023-05-05"},{"transaction_date":"2023-05-06"},{"transaction_date":"2023-05-08"}];

  return (
    <Wrapper>
      <Table data={XX} rows={rows} cols={cols} aggregatorName={aggregatorName} vals={vals} rendererName={rendererName}  />
      <DatePicker updateAction={getReportsXX}/>
    </Wrapper>
  );
};

export default Reports;
