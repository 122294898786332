import React from "react";

import Report from "./dash/Report";
import DarkReport from "./dash/DarkReport";

import { Card } from "@lightspeed/flame/Card";

import { useSelector } from "react-redux";

const Dash = () => {
  const userState = useSelector((state) => state.userState);
  const ui = useSelector((state) => state.ui);

  return (
    <Card className="dash">
      <h2>Welcome {userState.user.user}</h2>
      <p>
        last update: {new Date(userState.user.last_update).toLocaleDateString()}
      </p>
      <h2>User documentation</h2>
       
    </Card>
  );
};

export default Dash;

//{ui.theme === "flame" ? <Report /> : <DarkReport />}